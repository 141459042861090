import React, { useEffect, useState } from "react";
import {
  InputCheckboxComponent,
  InputComponent,
  InputSelectComponent,
} from "../InputComponent";
import ImageUploadComponent from "../ImageUploadComp";
import { AccordianComponent } from "../AccordianComponent";
import { PreviewFrame, TopPreviewHeader } from "../SVGIcon";
import {
  QRPreviewElabelsBeer,
  QRPreviewElabelsCigar,
  QRPreviewElabelsCoffee,
  QRPreviewElabelsFood,
  QRPreviewElabelsProduct,
  QRPreviewElabelsWine,
} from "./QRPreviewAll";
import ToggleButton from "./QRToggleButton";
import { useLocation } from "react-router-dom";
import ReviewFormBuilder from "../ReactFormBuilder";
import ViewPreviewModal from "../Modal/QR/ViewPreviewModal";
import ColorPickerComponent from "../ColorPicker";

const products = [
  "Wine/Spirits",
  "beer",
  "cigars",
  "coffee",
  "food",
  "product",
];

const options = [
  { value: "cava", label: "Cava" },
  { value: "champagne", label: "Champagne" },
  { value: "cremant", label: "Cremant" },
  { value: "fine_spirits", label: "Fine Spirits" },
  { value: "grape_juice", label: "Grape Juice" },
  { value: "ice_wine", label: "Ice Wine" },
  { value: "liquor", label: "Liquor" },
  { value: "prosecco", label: "Prosecco" },
  { value: "red_wine", label: "Red Wine" },
  { value: "rose_wine", label: "Rose Wine" },
  { value: "semi_sparkling", label: "Semi Sparkling" },
  { value: "sparkling", label: "Sparkling" },
  { value: "spritzer", label: "Spritzer" },
  { value: "white_wine", label: "White Wine" },
  { value: "other", label: "Other" },
];

const ELabels = ({ localQrData, setLocalQrData }) => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("Preview Page");
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [showCustomQuestion, setShowCustomQuestion] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("Wine/Spirits");
  const [showRatings, setShowRatings] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleToggle = (option) => {
    setSelectedOption(option);
  };

  console.log("checklocalqrdata", localQrData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalQrData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setLocalQrData((prev) => ({
      ...prev,
      [name]: value,
      customTypeWine: value === "other" ? prev.customTypeWine : "",
    }));
  };

  useEffect(() => {
    if (location.state?.qrData) {
      const localQrDataFromLocation = location?.state?.qrData?.data;
      // console.log("localQrDataFromLocation", localQrDataFromLocation);
      setLocalQrData(localQrDataFromLocation);
      setIsEditMode(true);

      // Initialize the state with all false values
      const updatedData = {
        wine: false,
        beer: false,
        cigars: false,
        coffee: false,
        food: false,
        product: false,
      };

      // Only set the value to true for the selected product in the API response
      Object.keys(localQrDataFromLocation).forEach((key) => {
        if (localQrDataFromLocation[key] === "true") {
          updatedData[key] = true;
        }
      });

      setLocalQrData((prevData) => ({
        ...prevData,
        ...updatedData, // Only update true values from API response
      }));

      // Set selected product based on the response
      const selected = Object.keys(localQrDataFromLocation).find(
        (key) => localQrDataFromLocation[key] === "true"
      );
      if (selected) {
        setSelectedProduct(selected);
      }

      if (localQrDataFromLocation?.is_question == "true") {
        setShowCustomQuestion(true);
      }
      if (localQrDataFromLocation?.is_rating == "true") {
        setShowRatings(true);
      }
    }
  }, [location.state?.qrData]);

  const handleProductSelection = (product) => {
    if (isEditMode && selectedProduct === product) return;

    setSelectedProduct(product);

    setLocalQrData((prevData) => ({
      ...prevData,
      wine: product === "Wine/Spirits",
      beer: product === "beer",
      cigars: product === "cigars",
      coffee: product === "coffee",
      food: product === "food",
      product: product === "product",
    }));
    // setLocalQrData(null);
  };

  // Use a separate effect to set selectedProduct based on localQrData changes
  useEffect(() => {
    if (localQrData) {
      const productMap = {
        "Wine/Spirits": localQrData.wine,
        beer: localQrData.beer,
        cigars: localQrData.cigars,
        coffee: localQrData.coffee,
        food: localQrData.food,
        product: localQrData.product,
      };

      for (const [product, isSelected] of Object.entries(productMap)) {
        if (isSelected) {
          setSelectedProduct(product);
          break; // Exit once we find the selected product
        }
      }
    }
  }, [localQrData]);

  //SET ADD REVIEW
  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === "ratings") {
      setShowRatings(!showRatings);
      setLocalQrData((prevData) => ({
        ...prevData,
        is_rating:
          prevData.is_rating === "true" || prevData.is_rating === true
            ? false
            : true,
      }));
    } else if (checkboxType === "questions") {
      setShowCustomQuestion(!showCustomQuestion);
      setLocalQrData((prevData) => ({
        ...prevData,
        // is_question: !prevData.is_question,
        is_question:
          prevData.is_question === "true" || prevData.is_question === true
            ? false
            : true,
      }));
    }
  };

  const handleImageDelete = (fieldName) => {
    // dispatch(resetField({ field: fieldName }));
    setLocalQrData((prevData) => ({
      ...prevData,
      [fieldName]: "",
    }));
  };

  // console.log("lcaooqrdtataelabels", localQrData);
  const handleTextColorChange = (field, value) => {
    setLocalQrData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return (
    <>
      <div className="app-page">
        <button
          className="viewPreviewbtn"
          onClick={() => setShowModalPreview(true)}
        >
          View Preview
        </button>
        {/* Buttons for selecting product */}
        <div className="product-selection-buttons">
          {products.map((product) => (
            <button
              key={product}
              onClick={() => handleProductSelection(product)}
              className={selectedProduct === product ? "selected" : ""}
              disabled={isEditMode && selectedProduct !== product}
            >
              {product}
            </button>
          ))}
        </div>

        {/* Accordion layout based on selected product */}
        {selectedProduct && (
          <div className="containerr">
            <div className="left">
              {selectedProduct === "Wine/Spirits" && (
                <>
                  <AccordianComponent title={"Choose your design"}>
                    <div
                      className="color-picker-con "
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <ColorPickerComponent
                        label=" Text Color"
                        color={localQrData.text_color}
                        setColor={(color) =>
                          handleTextColorChange("text_color", color)
                        }
                      />
                    </div>
                  </AccordianComponent>
                  <AccordianComponent title={"Wine/Spirits Details"}>
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Wine/Spirits Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      onImageDelete={handleImageDelete}
                      name="wine_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.wine_image}
                    />
                    <InputComponent
                      label="Product Name"
                      placeholder="Enter Product Name"
                      value={localQrData?.product_name}
                      name="product_name"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="SKU"
                      placeholder="Enter SKU"
                      value={localQrData?.sku}
                      name="sku"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <div className="checkbox-group mb-3">
                      <label>
                        Alcoholic
                        <input
                          type="radio"
                          name="alcohol_type"
                          value="alcoholic"
                          checked={localQrData?.alcohol_type === "alcoholic"}
                          onChange={(e) =>
                            setLocalQrData((prev) => ({
                              ...prev,
                              alcohol_type: e.target.value,
                            }))
                          }
                        />
                      </label>
                      <label>
                        Non-Alcoholic
                        <input
                          type="radio"
                          name="alcohol_type"
                          checked={
                            localQrData?.alcohol_type === "non-alcoholic"
                          }
                          value="non-alcoholic"
                          onChange={(e) =>
                            setLocalQrData((prev) => ({
                              ...prev,
                              alcohol_type: e.target.value,
                            }))
                          }
                        />
                      </label>
                    </div>
                    <InputSelectComponent
                      label={"Enter Type"}
                      name="select_type_wine"
                      value={localQrData?.select_type_wine}
                      onChange={handleSelectChange}
                      options={options}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    {/* Show input field only if 'Other' is selected */}
                    {localQrData?.select_type_wine === "other" && (
                      <InputComponent
                        type="text"
                        label={"Enter Type"}
                        placeholder="Enter custom type"
                        value={localQrData?.custom_type_wine}
                        name="custom_type_wine"
                        onChange={handleInputChange}
                        textColorStyle={{ color: localQrData?.text_color }}
                      />
                    )}
                    <InputComponent
                      label="Flavour"
                      placeholder="Enter Flavour"
                      value={localQrData?.flavour}
                      name="flavour"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Grape Variety"
                      placeholder="Enter Grape Variety"
                      value={localQrData?.grape_variety}
                      name="grape_variety"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Country of Origin"
                      placeholder="Enter Country Origin"
                      value={localQrData?.where_it_is_made}
                      name="where_it_is_made"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Alcohol Content"
                      placeholder="Enter Alcohol Content"
                      type="number"
                      value={localQrData?.alcohol_percentage}
                      name="alcohol_percentage"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />

                    <InputComponent
                      label="Tasting Notes"
                      placeholder="Enter Tasting Notes"
                      value={localQrData?.task_notes}
                      name="task_notes"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Year"
                      placeholder="Enter Year"
                      type="number"
                      value={localQrData?.year}
                      name="year"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Description"
                      placeholder="Enter Description"
                      value={localQrData?.description}
                      name="description"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Drinking Temperature"
                      placeholder="Enter Drinking Temperature"
                      value={localQrData?.drinking_temperature}
                      name="drinking_temperature"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Stored Temperature Unopened"
                      placeholder="Enter Stored Temperature Unopened"
                      value={localQrData?.stored_temp_unopen}
                      name="stored_temp_unopen"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Stored Temperature Opened"
                      placeholder="Enter Stored Temperature Opened"
                      value={localQrData?.stored_temp_open}
                      name="stored_temp_open"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Stored for (days) after opening"
                      placeholder="Enter Stored for (days) after opening"
                      value={localQrData?.stored_days_open}
                      name="stored_days_open"
                      // type="number"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Nutritional Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      onImageDelete={handleImageDelete}
                      name="nutrition_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.nutrition_image}
                    />
                    <InputComponent
                      label="Website"
                      placeholder="Enter Website URL"
                      value={localQrData?.website}
                      name="website"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                  </AccordianComponent>
                </>
              )}

              {selectedProduct === "beer" && (
                <>
                  <AccordianComponent title={"Choose your design"}>
                    <div
                      className="color-picker-con "
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <ColorPickerComponent
                        label=" Text Color"
                        color={localQrData.text_color}
                        setColor={(color) =>
                          handleTextColorChange("text_color", color)
                        }
                      />
                    </div>
                  </AccordianComponent>
                  <AccordianComponent title={"Beer Details"}>
                    <InputComponent
                      label="Product Name"
                      placeholder="Enter Product Name"
                      value={localQrData?.product_name}
                      name="product_name"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="SKU"
                      placeholder="Enter SKU"
                      value={localQrData?.sku}
                      name="sku"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="beer Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      name="beer_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.beer_image}
                    />
                    <InputComponent
                      label="Description"
                      placeholder="Enter Description"
                      value={localQrData?.description}
                      name="description"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Alcohol %"
                      placeholder="Enter Alcohol Percentage"
                      value={localQrData?.alcohol_percentage}
                      name="alcohol_percentage"
                      type="number"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="IPA"
                      placeholder="Enter IPA"
                      value={localQrData?.ipa}
                      name="ipa"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Nutrition Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      name="nutrition_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.nutrition_image}
                    />
                    <InputComponent
                      label="Brewed"
                      placeholder="Enter Brewing Info"
                      value={localQrData?.brewed}
                      name="brewed"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Website"
                      placeholder="Enter Website URL"
                      value={localQrData?.website}
                      name="website"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                  </AccordianComponent>
                </>
              )}

              {selectedProduct === "cigars" && (
                <>
                  <AccordianComponent title={"Choose your design"}>
                    <div
                      className="color-picker-con "
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <ColorPickerComponent
                        label=" Text Color"
                        color={localQrData.text_color}
                        setColor={(color) =>
                          handleTextColorChange("text_color", color)
                        }
                      />
                    </div>
                  </AccordianComponent>
                  <AccordianComponent title={"Cigars Details"}>
                    <InputComponent
                      label="Product Name"
                      placeholder="Enter Product Name"
                      value={localQrData?.product_name}
                      name="product_name"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="SKU"
                      placeholder="Enter SKU"
                      value={localQrData?.sku}
                      name="sku"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Cigars Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      onImageDelete={handleImageDelete}
                      name="cigar_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.cigar_image}
                    />
                    <InputComponent
                      label="Description"
                      placeholder="Enter Description"
                      value={localQrData?.description}
                      name="description"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Where it is made"
                      placeholder="Enter Origin"
                      value={localQrData?.where_it_is_made}
                      name="where_it_is_made"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Size"
                      placeholder="Enter Size"
                      value={localQrData?.size}
                      name="size"
                      type="text"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Wrapper"
                      placeholder="Enter Wrapper"
                      value={localQrData?.wrapper}
                      name="wrapper"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Binder"
                      placeholder="Enter Binder"
                      value={localQrData?.binder}
                      name="binder"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Filler"
                      placeholder="Enter Filler"
                      value={localQrData?.filler}
                      name="filler"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Strength"
                      placeholder="Enter Strength"
                      value={localQrData?.strength}
                      name="strength"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Body"
                      placeholder="Enter Body"
                      value={localQrData?.body}
                      name="body"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />

                    <InputComponent
                      label="Flavour Profile"
                      placeholder="Enter Flavour Profile"
                      value={localQrData?.flavour_profile}
                      name="flavour_profile"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Best Paired With"
                      placeholder="Enter Best Pairing"
                      value={localQrData?.best_paired_with}
                      name="best_paired_with"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Website"
                      placeholder="Enter Website URL"
                      value={localQrData?.website}
                      name="website"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                  </AccordianComponent>
                </>
              )}

              {selectedProduct === "coffee" && (
                <>
                  <AccordianComponent title={"Choose your design"}>
                    <div
                      className="color-picker-con "
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <ColorPickerComponent
                        label=" Text Color"
                        color={localQrData.text_color}
                        setColor={(color) =>
                          handleTextColorChange("text_color", color)
                        }
                      />
                    </div>
                  </AccordianComponent>

                  <AccordianComponent title={"Coffee Details"}>
                    <InputComponent
                      label="Product Name"
                      placeholder="Enter Product Name"
                      value={localQrData?.product_name}
                      name="product_name"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="SKU"
                      placeholder="Enter SKU"
                      value={localQrData?.sku}
                      name="sku"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Coffee Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      onImageDelete={handleImageDelete}
                      name="coffee_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.coffee_image}
                    />
                    <InputComponent
                      label="Description"
                      placeholder="Enter Description"
                      value={localQrData?.description}
                      name="description"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Origin"
                      placeholder="Enter Origin"
                      value={localQrData?.origin}
                      name="origin"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Farm"
                      placeholder="Enter Farm"
                      value={localQrData?.farm}
                      name="farm"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Altitude"
                      placeholder="Enter Altitude"
                      value={localQrData?.altitude}
                      type="number"
                      name="altitude"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Roast"
                      placeholder="Enter Roast"
                      value={localQrData?.roast}
                      name="roast"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Flavour"
                      placeholder="Enter Flavour"
                      value={localQrData?.flavour}
                      name="flavour"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Tasting Notes"
                      placeholder="Enter Tasting Notes"
                      value={localQrData?.task_notes}
                      name="task_notes"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Ingredients"
                      placeholder="Enter Ingredients"
                      value={localQrData?.ingredients}
                      name="ingredients"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Nutritional Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      onImageDelete={handleImageDelete}
                      name="nutrition_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.nutrition_image}
                    />
                    <InputComponent
                      label="Website"
                      placeholder="Enter Website URL"
                      value={localQrData?.website}
                      name="website"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Storage"
                      placeholder="Enter Storage Instructions"
                      value={localQrData?.storage}
                      name="storage"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <div className="checkbox-group">
                      <label>
                        Organic
                        <input
                          type="checkbox"
                          name="organic"
                          checked={
                            localQrData?.organic === true ||
                            localQrData?.organic === "true"
                          }
                          onChange={(e) =>
                            setLocalQrData((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.checked,
                            }))
                          }
                        />
                      </label>
                      <label>
                        Fare Trade
                        <input
                          type="checkbox"
                          name="free_trade"
                          checked={
                            localQrData?.free_trade === true ||
                            localQrData?.free_trade === "true"
                          }
                          onChange={(e) =>
                            setLocalQrData((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.checked,
                            }))
                          }
                        />
                      </label>
                    </div>
                  </AccordianComponent>
                </>
              )}

              {selectedProduct === "food" && (
                <>
                  <AccordianComponent title={"Choose your design"}>
                    <div
                      className="color-picker-con "
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <ColorPickerComponent
                        label=" Text Color"
                        color={localQrData.text_color}
                        setColor={(color) =>
                          handleTextColorChange("text_color", color)
                        }
                      />
                    </div>
                  </AccordianComponent>
                  <AccordianComponent title={"Food Details"}>
                    <InputComponent
                      label="Food Name"
                      placeholder="Enter Food Name"
                      value={localQrData?.product_name}
                      name="product_name"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Cuisine"
                      placeholder="Enter Cuisine"
                      value={localQrData?.cuisine}
                      name="cuisine"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Ingredients"
                      placeholder="Enter Ingredients"
                      value={localQrData?.ingredients}
                      name="ingredients"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Serving Size"
                      placeholder="Enter Serving Size"
                      type="text"
                      value={localQrData?.size}
                      name="size"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Calories"
                      placeholder="Enter Calories"
                      type="number"
                      value={localQrData?.clories}
                      name="clories"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Food Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      onImageDelete={handleImageDelete}
                      name="food_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.food_image}
                    />
                    <InputComponent
                      label="Website"
                      placeholder="Enter Website URL"
                      value={localQrData?.website}
                      name="website"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                  </AccordianComponent>
                </>
              )}

              {selectedProduct === "product" && (
                <>
                  <AccordianComponent title={"Choose your design"}>
                    <div
                      className="color-picker-con "
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                      }}
                    >
                      <ColorPickerComponent
                        label=" Text Color"
                        color={localQrData.text_color}
                        setColor={(color) =>
                          handleTextColorChange("text_color", color)
                        }
                      />
                    </div>
                  </AccordianComponent>
                  <AccordianComponent title={"Product Details"}>
                    <InputComponent
                      label="Product Name"
                      placeholder="Enter Product Name"
                      value={localQrData?.product_name}
                      name="product_name"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="SKU"
                      placeholder="Enter SKU"
                      value={localQrData?.sku}
                      name="sku"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <ImageUploadComponent
                      defaultImage="/assets/images/default-img.png"
                      label="Product Image"
                      onImageUpload={(imageUrl, name, file) => {
                        setLocalQrData((prev) => ({
                          ...prev,
                          [name]: file,
                        }));
                      }}
                      name="product_image"
                      localQrData={localQrData}
                      onEditImagePreview={localQrData?.product_image}
                    />
                    <InputComponent
                      label="Description"
                      placeholder="Enter Description"
                      value={localQrData?.description}
                      name="description"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Direction"
                      placeholder="Enter Direction"
                      value={localQrData?.directions}
                      name="directions"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Brand"
                      placeholder="Enter Brand"
                      value={localQrData?.brand}
                      name="brand"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Category"
                      placeholder="Enter Category"
                      value={localQrData?.category}
                      name="category"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                    <InputComponent
                      label="Price"
                      placeholder="Enter Price"
                      value={localQrData?.price}
                      type="number"
                      name="price"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />

                    <InputComponent
                      label="Warning"
                      placeholder="Enter Warning"
                      value={localQrData?.warning}
                      name="warning"
                      onChange={handleInputChange}
                      textColorStyle={{ color: localQrData?.text_color }}
                    />
                  </AccordianComponent>
                </>
              )}

              <AccordianComponent title={"Add Review"}>
                <div className="d-flex">
                  <InputCheckboxComponent
                    label={"Add Ratings"}
                    onChange={() => handleCheckboxChange("ratings")}
                    checked={showRatings}
                  />
                  <InputCheckboxComponent
                    label={"Add Questions"}
                    onChange={() => {
                      setShowCustomQuestion(!showCustomQuestion);
                      handleCheckboxChange("questions");
                    }}
                    checked={showCustomQuestion}
                  />
                </div>

                {showCustomQuestion && (
                  <ReviewFormBuilder
                    setShowCustomQuestion={setShowCustomQuestion}
                    showCustomQuestion={showCustomQuestion}
                    localQrData={localQrData}
                    setLocalQrData={setLocalQrData}
                  />
                )}
              </AccordianComponent>
            </div>
            <div className="right">
              <ToggleButton
                selectedOption={selectedOption}
                onToggle={handleToggle}
              />
              <div className="qr-preview__layout__image">
                <div className="Preview-layout Preview-layout--vcard">
                  <TopPreviewHeader
                    className="topHeaderSvg"
                    urlLink={localQrData?.field_url}
                  />
                  {selectedProduct === "beer" ? (
                    <QRPreviewElabelsBeer localQrData={localQrData} />
                  ) : selectedProduct === "coffee" ? (
                    <QRPreviewElabelsCoffee localQrData={localQrData} />
                  ) : selectedProduct === "cigars" ? (
                    <QRPreviewElabelsCigar localQrData={localQrData} />
                  ) : selectedProduct === "food" ? (
                    <QRPreviewElabelsFood localQrData={localQrData} />
                  ) : selectedProduct === "product" ? (
                    <QRPreviewElabelsProduct localQrData={localQrData} />
                  ) : (
                    <QRPreviewElabelsWine localQrData={localQrData} />
                  )}
                </div>

                <PreviewFrame className="preview-frame" />
              </div>
              {/* <img src="/assets/images/phone-website.png" alt="phone-website" /> */}
            </div>
          </div>
        )}
      </div>
      <ViewPreviewModal
        setShowModalPreview={setShowModalPreview}
        showModalPreview={showModalPreview}
        localQrData={localQrData}
        type={"elabels"}
        elabelsSelectedProduct={selectedProduct}
      />
    </>
  );
};

export default ELabels;
